.mainNav {
    height: 80px;
    width: 80%;
    // margin: 0 auto;
    background-color: $white;
    border-radius: 40px;
    position: fixed;
    z-index: 999;
    top: 24px;
    .mainNav__btn {
        color: $dark;
        font-size: medium;
        transition: all 0.6s;
        &:hover {
            color: $darkTxt;
            transform: translateY(-10%);
        }
    }
    @include pad-width {
        width: 90%;
    }
    @include phone-width{
        height: 60px;
        .mainNav__logo{
            img{
                height: 32px;
            }
        }
    }
    .mainNav__downLoad {
        display: flex;
        align-items: center;
        background: linear-gradient(#ffae00, #e79f0f);
        background-color: $bgColor;
        color: $white;
        img {
            width: 20px;
            height: 20px;
        }
        &:hover {
            color: $darkTxt;
        }
    }
}
.hamBtn {
    width: 44px;
    height: 44px;
    .hamBtn__line {
        background-color: $dark;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        transition: all 0.6s;
    }
    &.active {
        .hamBtn__line:nth-child(2) {
            // transform: translateX(50%);
            width: 12px;
        }
        .hamBtn__line:nth-child(3) {
            // transform: translateX(100%);
            width: 8px;
        }
    }
}
.hamMenu {
    background-color: $bgColor;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 299;
    height: 0vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s;
    .hamMenu__items {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $white;
        margin-bottom: 24px;
        height: 44px;
        transition: all 0.6s;
    }
    &.active{
        height: 100vh;
    }
}
.footer__followUs__fb {
    transition: all 0.6s;
}
.footer__followUs__fb:hover {
    opacity: 0.5;
}
.footer{
    li{
        @include phone-width{
        text-align: center;
        }
    }
}