//header區塊
.header {
    height: 800px;
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;
    @include phone-width {
        padding-top: 60px;
    }
    //header banner中的內容物
    .header__txtBox {
        z-index: 99;
    }
    .header__imgBox {
        position: absolute;
        bottom: 0;
        z-index: 99;
        .header__imgBox__pic {
            width: 100%;
        }
    }
    .header__imgBox--boy {
        left: 50px;
        width: 30%;
        @include pad-width() {
            left: -50px;
            width: 50%;
        }
    }
    .header__imgBox--girl {
        right: 50px;
        width: 20%;
        @include pad-width() {
            right: 35px;
            width: 30%;
        }
        @include phone-width() {
            right: 15px;
            width: 30%;
        }
    }
    .header__scrollBox {
        border: 2px solid $dark;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $dark;
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
        }
        @include phone-width {
            border: 2px solid $dark;
            width: 60px;
            height: 60px;
            font-size: 12px;
            &::after {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $dark;
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .header__scrollBox::after {
        animation-name: scrollAnimal;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    @keyframes scrollAnimal {
        0% {
            bottom: 10%;
        }
        50% {
            bottom: 30%;
        }
        100% {
            bottom: 10%;
        }
    }
    .headerBg {
        background-image: url(../images/header-bg.svg);
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        @include phone-width {
            background-position: 60% 120px;
            background-repeat: no-repeat;
        }
    }
    &::after {
        content: '';
        background-color: $bgColor;
        width: 100%;
        height: 300px;
        position: absolute;
        bottom: 0;
        z-index: 50;
        transform: translateY(50%);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        @include phone-width {
            width: 120%;
        }
    }
}
//手機＆手的區塊
.section-job {
    position: relative;

    .section-job__hand {
        transform: translateX(5%);
        @include phone-width {
            width: 95%;
        }
    }

    //文字區塊
    .section-job__txtBox {
        margin-top: -200px;
        padding-bottom: 120px;
        @include pad-width {
            margin-top: 120px;
            padding-bottom: 48px;
        }
    }
}
.jobsImgList-container {
    width: 100%;
    top: 0%;
    transform: translateY(-20%);
    @include phone-width {
        width: 50%;
        transform: translateY(0%);
    }
    .jobsImgBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 120px;
        z-index: 10;
        padding: 4px 24px;
        background-color: $white;
        .jobsImg {
            width: 100%;
            transform: translateY(24px);
            transition: all 0.3s;
        }
        p {
            // display: none;
            color: $dark;
            visibility: hidden;
            margin-top: 16px;
            margin-bottom: 16px;
            transition: all 0.3s;
        }
        @include phone-width {
            padding: 4px 24px;
        }
    }
}

//聊天對話區塊
.section--chat {
    padding-top: 120px;
    @include phone-width {
        padding-top: 56px;
    }
    .chatBox {
        width: 60%;
        border: 4px solid $white;
        border-radius: 16px;
        font-size: 24px;
        @include pad-width {
            width: 80%;
        }
        @include phone-width {
            width: 90%;
            font-size: 16px;
        }
    }
    .chatBox__img {
        img {
            width: 80px;
            @include phone-width {
                width: 44px;
            }
        }
    }
    .chatBox--tga {
        border-bottom-left-radius: 0px;
    }
    .chatBox--user {
        border-bottom-right-radius: 0px;
    }
    .weChatDownLoad {
        padding: 120px 0;
        width: 80%;
        margin: 0 auto;
        @include phone-width {
            width: 100%;
            .title-md:last-child() {
                font-size: 20px;
            }
        }
        .weChatDownLoad__boxList {
            margin-left: -50px;
            margin-right: -50px;
            @include phone-width {
                margin: 0;
            }
        }
        .weChatDownLoad__box {
            width: calc(33.333% - 35px);
            height: 300px;
            background-color: $white;
            border-radius: 8px;
            border: 2px solid $bgColor;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .box__title {
                display: block;
                background-color: $bgColor;
                padding: 4px 32px;
                border-radius: $spacer;
            }
            .box__txt {
                color: #8a5c26;
            }
            img {
                width: 100%;
            }
            @include pad-width {
                width: calc(33.333% - 25px);
            }
            @include phone-width {
                width: 80%;
                height: 240px;
            }
        }
        .arrow::after {
            content: '';
            background: url('../images/arrow.svg') no-repeat;
            background-size: contain;
            position: absolute;
            z-index: 99;
            width: 70px;
            height: 60px;
            @include phone-width {
                display: none;
            }
        }
        .arrow--right::after {
            right: -70px;
            bottom: 50%;
            transform: translateY(50%);
            @include pad-width {
                width: 50px;
                right: -50px;
            }
        }
        .arrow--left::after {
            left: -70px;
            bottom: calc(50% - 30px);
            transform: rotate(180deg);
            @include pad-width {
                width: 50px;
                left: -50px;
            }
        }
        .arrow--bottom::after {
            right: calc(50% - 35px);
            bottom: -65px;
            transform: rotate(90deg);
            @include pad-width {
                width: 50px;
                bottom: -55px;
                right: calc(50% - 20px);
            }
        }
    }
}

//下載區塊
.section-downLoad {
    background-color: rgba(255, 255, 255, 0.5); /* IE9、標準瀏覽器、IE6和部分IE7內核的瀏覽器(如QQ瀏覽器)會讀懂*/
    .section-downLoad__txt {
        display: block;
        width: 80px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .section-downLoad__qrcode {
        margin-left: 8px;
        flex-direction: column;
        align-items: flex-start;
    }
}
