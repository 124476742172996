// * {
//     outline: 1px solid red;
// }

// 字型設定：

// Text：Noto Sans CJK TC
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

.ff-txt {
    font-family: 'Noto Sans TC', sans-serif;
}
* {
    font-family: 'Noto Sans TC', sans-serif;
}

ol,
ul {
    list-style: none;
    padding-inline-start: 0px;
}
* {
    box-sizing: border-box;
}
html {
    font-size: 16px;
}
h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
}
p,
a,
li,
label,
span,
* {
    line-height: 1.5;
}

img {
    display: block;
}

//以下是改bs4效果
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0;
}

a:hover {
    text-decoration: none;
}
p {
    margin-bottom: 0;
}
ol,
ul {
    margin-bottom: 0;
    list-style: none;
}
// 共用class--格線

.wrap {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}
.wrap-fulid {
    width: 100%;
    // margin: 0 auto;
}

// 共用class

// 共用class--客制尺寸

// 共用class--客制按鈕

// 共用class--客制分頁

// 共用class--其他
.bdrs-1 {
    border-radius: 1rem;
}

.cardBox {
    .cardBox__img {
        width: 100%;
    }
}
