//文字大小
$title-sb: 72px;
$title-lg: 64px;
$title-md: 48px;
$title-sm: 32px;
$font-lg: 24px;
$font-md: 20px;

//字重
$fw-sm: 200;
$fw-rg: 400;
$fw-md: 500;
$fw-bd: 700;
$fw-bk: 900;
